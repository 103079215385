import React, { useEffect, useState } from 'react';
import { PrivacyPolicy } from '@b3w/pp-portal';
import axios from 'axios';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../../common/styles/main.sass';
import { Statistic } from '@b3w/react-statistic';
import Routes from '../Routes/Routes';
import Footer from '../Footer/Footer';
import ModalVideo from '../ModalVideo/ModalVideo';
import ScrollTop from '../ScrollTop/ScrollTop';
import '@b3w/pp-portal/dist/portal-bundle.css';
import { CONDITIONS_FOOTER, ADDRESS_FOOTER, BASE_PATH } from '../../constants/constants';
import AddToHomeScreen from '../AddToHomeScreen/AddToHomeScreen';
import useFirebase from '../../hooks/useFirebase';

function App() {
  const [text, setText] = useState('');
  const [address, setAddress] = useState('');
  const [dangerousText, setDangerousText] = useState('');

  useFirebase();

  useEffect(() => {
    axios.get(`${BASE_PATH}${CONDITIONS_FOOTER}`)
      .then((response) => {
        if (response.status === 200) {
          setText(response.data);
        }
      })
      .catch(() => {});
    axios.get(`${BASE_PATH}${ADDRESS_FOOTER}`)
      .then((response) => {
        if (response.status === 200) {
          setAddress(response.data);
        }
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    axios.get(`${BASE_PATH}/text/common-text-service/privacyfooter/1`)
      .then((res) => {
        if (res.data) {
          const regExp = /%.*%/;
          const match = res.data.match(regExp);
          const substr = match ? match[0] : '';
          const linkName = substr ? substr.slice(1, substr.length - 1) : '';
          const linkHtml = `<a href="http://lk.funzone.by/privacypolicy/?service=rb-mts-video">
                        ${linkName}</a>`;
          const html = res.data.replace(substr, linkHtml);
          setDangerousText(html);
        }
      })
      .catch(() => {});
  }, []);

  return (
    <>
      <Statistic path={`${BASE_PATH}/referer/statistic`} from timeoutMinutes={1} />
      <div className="Main">
        <Routes />
      </div>
      { dangerousText ? <PrivacyPolicy dangerousText={dangerousText} adoption /> : null }
      <Footer text={text} entity={address} />
      <AddToHomeScreen />
      <ModalVideo />
      <ScrollTop />
    </>
  );
}

export default App;
