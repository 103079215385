import React from 'react';
import { cn } from '@bem-react/classname';
import ReactModal from 'react-modal';
import { setModalIsOpen } from '../../redux/reducers/app';
import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import { ModalContent, IsModalOpen } from '../../redux/types';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

import './ModalVideo.sass';

ReactModal.setAppElement('#modal');

function ModalVideo() {
  const cnModalVideo = cn('ModalVideo');
  const dispatch = useAppDispatch();
  const isModalOpen: IsModalOpen = useAppSelector((state) => state.app.isModalOpen);
  const modalContent: ModalContent = useAppSelector((state) => state.app.modalContent);

  const closeHandle = () => {
    dispatch(setModalIsOpen({ isModalOpen: false, modalContent: null }));
  };

  return (
    <ReactModal
      onRequestClose={closeHandle}
      closeTimeoutMS={100}
      className={cnModalVideo('Modal')}
      isOpen={isModalOpen}
      parentSelector={() => document.querySelector('#modal')}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          zIndex: 9999,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
    >
      <button
        type="button"
        onClick={closeHandle}
        className={cnModalVideo('Close')}
      >
        &nbsp;
      </button>
      {modalContent
        ? <VideoPlayer extraClass={cnModalVideo('Player')} content={modalContent} />
        : <div>loading...</div>}
    </ReactModal>
  );
}

export default ModalVideo;
