import React from 'react';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import { cn } from '@bem-react/classname';
import { classnames } from '@bem-react/classnames';
import { IContent } from '../../redux/types';
import { BASE_PATH, GATE } from '../../constants/constants';

import './VideoPlayer.sass';
import { useLazyFetchDataQuery } from '../../redux/reducers/data';

interface IPlayer {
  extraClass?: string;
  content: IContent;
}

function VideoPlayer({ extraClass, content }: IPlayer) {
  const [fetchData] = useLazyFetchDataQuery();
  const cnVideoPlayer = cn('VideoPlayer');
  const { uidx, file_hash: fileHash } = content;

  const isTablet: boolean = useMediaQuery({ minWidth: 601, maxWidth: 1280 });
  const isMobile: boolean = useMediaQuery({ maxWidth: 600 });

  const getMaxWidth = (): string => {
    if (isMobile) return '100%';
    if (isTablet) return '600px';
    return '1200px';
  };

  const checkAuthStatus = () => {
    fetchData();
  };

  return (
    <div className={classnames(cnVideoPlayer(), extraClass)}>
      <ReactPlayer
        url={`${BASE_PATH}${GATE}/d/${uidx}/${fileHash}`}
        onError={checkAuthStatus}
        controls
        width={getMaxWidth()}
        height="auto"
        style={{
          background: '#333',
          display: 'block',
        }}
        config={{
          file: {
            forceVideo: true,
          },
        }}
      />
    </div>
  );
}

VideoPlayer.defaultProps = {
  extraClass: '',
};

export default VideoPlayer;
