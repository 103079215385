import { useEffect } from 'react';
import { firebaseMsisdn } from '../helpers/firebase';
import { useFetchDataQuery } from '../redux/reducers/data';

function useFirebase() {
  const { data } = useFetchDataQuery();
  const msisdn = data?.user?.msisdn;

  useEffect(() => {
    if (msisdn) {
      firebaseMsisdn(msisdn);
    }
  }, [msisdn]);
}

export default useFirebase;
