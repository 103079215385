import React from 'react';
import { createRoot } from 'react-dom/client';
import { initializeApp } from 'firebase/app';
import Root from './components/Root/Root';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { firebaseConfig } from './constants/firebase';
import { firebaseRegistration } from './helpers/firebase';

initializeApp(firebaseConfig);

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(<Root />);
}

reportWebVitals();

// Service worker
serviceWorkerRegistration.register({
  onSuccess: firebaseRegistration,
  onUpdate: firebaseRegistration,
});
