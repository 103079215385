export const firebaseProjectToken = '4ccad9bd-1ea2-4b73-a464-73cb8da4379a';

export const firebaseVapidKey = 'BE03VLXR0_KRn97hdPjE_XTaPDXTBDb5KBUT4VL4uWxLWNvCo2HAs5q_KWLwbiONxZift0HUVDXHVwdneEFKanI';

export const firebaseConfig = {
  apiKey: 'AIzaSyCfAd5LZxr73INtEt1IyifzvAc9scdXsCI',
  authDomain: 'jfl-rb-mts.firebaseapp.com',
  projectId: 'jfl-rb-mts',
  storageBucket: 'jfl-rb-mts.appspot.com',
  messagingSenderId: '428770992023',
  appId: '1:428770992023:web:b5dae1b4ba99a034102ed2',
};
