import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useMediaQuery } from 'react-responsive';
import { cn } from '@bem-react/classname';
import useAddToHomeScreen from '../../hooks/useAddToHomeScreen';
import { checkIsIOS, checkIsInStandaloneMode } from '../../helpers/platform';
import { ReactComponent as XIcon } from '../../common/images/x.svg';
import { ReactComponent as IOSMenu } from '../../common/images/ios-menu.svg';

import './AddToHomeScreen.sass';

function AddToHomeScreen() {
  const mcn = cn('AddToHomeScreen');

  const isLaptop = useMediaQuery({ query: '(min-width: 1280px)' });

  const [show, setShow] = useState<boolean>(false);
  const [canShow, setPromptCanShow] = useState<boolean>(false);

  const portal = document.getElementById('add-to-home-screen-portal');

  const { prompt, promptToInstall } = useAddToHomeScreen();

  const canInstallApp = !!prompt;
  const isIOS = checkIsIOS();
  const isInStandaloneMode = checkIsInStandaloneMode();
  const canShowModal = canShow && (canInstallApp || (isIOS && !isInStandaloneMode));

  const handleClose = () => setShow(false);
  const handleInstall = () => {
    promptToInstall();
    handleClose();
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = document.documentElement.scrollTop;
      const availHeight = window.screen?.availHeight || 0;

      if (scrollPosition >= availHeight) setPromptCanShow(true);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (canShowModal) setShow(true);
  }, [canShowModal]);

  const homePageName = () => {
    if (isLaptop) return 'рабочий стол';
    return 'домашний экран';
  };

  const serviceName = 'FUNZONEVIDEO';
  const installAppText = `Добавить ярлык «${serviceName}» на ${homePageName()}?`;

  const imgSrc = `${process.env.PUBLIC_URL}/icon-192x192.png`;

  const installAppModalEl = (
    <div
      className={mcn('Wrap', { InstallApp: true })}
      onClick={handleInstall}
      role="textbox"
      tabIndex={0}
      onKeyUp={() => {}}
    >
      <img
        className={mcn('Image', { InstallApp: true })}
        src={imgSrc}
        alt=""
      />
      <div
        className={mcn('Text', { InstallApp: true })}
      >
        {installAppText}
      </div>
      <div
        className={mcn('Button', { InstallApp: true })}
      >
        Добавить
      </div>
      <button
        className={mcn('CloseButton', { InstallApp: true })}
        type="button"
        onClick={(ev) => {
          ev.stopPropagation();
          handleClose();
        }}
      >
        <XIcon />
      </button>
    </div>
  );
  const IOSModalEl = (
    <div
      className={mcn('Wrap', { iOS: true })}
    >
      <img
        className={mcn('Image', { iOS: true })}
        src={imgSrc}
        alt=""
      />
      <div
        className={mcn('Text', { iOS: true })}
      >
        <div>{installAppText}</div>
        <div>
          Нажмите на
          <IOSMenu />
          и выберите «На экран «Домой»
        </div>
      </div>
      <button
        className={mcn('CloseButton', { iOS: true })}
        type="button"
        onClick={handleClose}
      >
        <XIcon />
      </button>
    </div>
  );

  const modalEl = () => {
    if (canInstallApp) return installAppModalEl;
    if (isIOS) return IOSModalEl;
    return null;
  };

  if (show && portal) {
    return createPortal(modalEl(), portal);
  }

  return null;
}

export default AddToHomeScreen;
