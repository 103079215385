import { configureStore } from '@reduxjs/toolkit';
import app from './reducers/app';
import { dataApi } from './reducers/data';

const store = configureStore({
  reducer: {
    [app.name]: app.reducer,
    [dataApi.reducerPath]: dataApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(dataApi.middleware),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
