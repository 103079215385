import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  IDataState,
} from '../types';
import { API_PATH, BASE_PATH } from '../../constants/constants';

export const dataApi = createApi({
  reducerPath: 'data',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_PATH,
  }),
  endpoints: (builder) => ({
    fetchData: builder.query<IDataState, void>({
      query: () => ({
        url: API_PATH,
      }),
    }),
  }),
});

export const { useFetchDataQuery, useLazyFetchDataQuery } = dataApi;
