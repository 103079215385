import React from 'react';
import HeaderMain from '../components/HeaderMain/HeaderMain';
import useTranslate from '../hooks/useTranslate';
import { useAppSelector } from '../hooks/redux';

function AboutPage() {
  const isTranslated = useAppSelector((state) => state.app.isTranslated);

  return (
    <>
      <HeaderMain />
      <div style={{
        flex: '1 1',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <p style={{
          fontSize: '120px',
          fontWeight: 900,
          color: '#666',
          margin: 0,
        }}
        >
          <span style={{ color: '#72be44' }}>4</span>
          0
          <span style={{ color: '#72be44' }}>4</span>
        </p>
        <p style={{
          fontSize: '20px',
          fontWeight: 300,
          color: '#666',
          margin: 0,
          textTransform: 'uppercase',
        }}
        >
          {useTranslate('Страница', 'Sahifa')}
          {' '}
          {isTranslated ? null : (
            <>
              <span style={{ color: '#72be44' }}>не</span>
              {' '}
            </>
          )}
          <span style={{ color: '#72be44' }}>
            {useTranslate('найдена', 'topilmadi')}
          </span>
        </p>
      </div>
    </>
  );
}

export default AboutPage;
