import React from 'react';
import { cn } from '@bem-react/classname';
import { Link } from 'react-router-dom';
import { classnames } from '@bem-react/classnames';
import logo from '../../common/images/logo.png';
import './Footer.sass';

interface IFooter {
  extraClass?: string;
  text: string;
  entity: string;
}

function Footer({ extraClass, text, entity }: IFooter) {
  const cnFooter = cn('Footer');
  const cnText = cn('Text');

  const handleScroll = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className={classnames(cnFooter(), extraClass)}>
      <div className={classnames(cnFooter('Controls'))}>
        <img className={classnames(cnFooter('Image'))} src={logo} alt="FunZone" />
      </div>
      <div className={classnames(cnFooter('Conditions'), cnText({ theme: 'dark' }))}>
        <div className={cnFooter('Links')}>
          <Link
            to="/user/conditions"
            className={cnFooter('Link')}
            onClick={handleScroll}
          >
            Пользовательское соглашение
          </Link>
        </div>
        {/* eslint-disable react/no-danger */}
        <div className={cnFooter('Text')} dangerouslySetInnerHTML={{ __html: text }} />
        <div className={cnFooter('Text')} dangerouslySetInnerHTML={{ __html: entity }} />
      </div>
    </footer>
  );
}

Footer.defaultProps = {
  extraClass: '',
};

export default Footer;
