import React, { useEffect, useState } from 'react';
import { classnames } from '@bem-react/classnames';
import { cn } from '@bem-react/classname';
import { ReactComponent as IconScrollUp } from '../../common/images/scroll-up.svg';
import './ScrollTop.sass';

function ScrollTop() {
  const cnScrollTop = cn('ScrollTop');

  const [isShow, setShow] = useState(false);

  const handleScroll = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const appear = () => {
      if (window.scrollY > 600) {
        setShow(true);
      } else {
        setShow(false);
      }
    };

    window.addEventListener('scroll', appear);

    return () => {
      window.removeEventListener('scroll', appear);
    };
  }, []);

  if (!isShow) return null;

  return (
    <button type="button" className={classnames(cnScrollTop('Btn'))} onClick={handleScroll}>
      <IconScrollUp />
    </button>
  );
}

export default ScrollTop;
