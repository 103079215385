import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from '../App/App';
import store from '../../redux/store';
import { BASE_PATH } from '../../constants/constants';

function Root() {
  return (
    <Provider store={store}>
      <BrowserRouter basename={BASE_PATH}>
        <App />
      </BrowserRouter>
    </Provider>
  );
}

export default Root;
